async function initMocks() {
	if (process.env.NEXT_PUBLIC_API_MOCKING !== "enabled") {
		return;
	}
	if (typeof window === "undefined") {
		const { server } = await import("./server");
		server.listen({
			onUnhandledRequest(req, print) {
				if (req.url.includes("localhost:8080")) {
					return;
				}

				print.warning();
			},
		});
	} else {
		const { worker } = await import("./browser");
		return worker.start({
			onUnhandledRequest(req, print) {
				if (req.url.includes("localhost:8080")) {
					return;
				}

				print.warning();
			},
		});
	}
}

void initMocks();

export {};
