import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import IconButton from "@mui/joy/IconButton";
import Tooltip from "@mui/joy/Tooltip";
import { useColorScheme } from "@mui/joy/styles";
import { useTranslation } from "next-i18next";
import React from "react";

export function ModeToggle() {
	const { t } = useTranslation();
	const { mode, setMode } = useColorScheme();
	const [mounted, setMounted] = React.useState(false);
	React.useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return <IconButton size="sm" variant="outlined" color="primary" />;
	}
	return (
		<Tooltip
			title={mode === "light" ? t("DarkMode") : t("LightMode")}
			variant="soft"
		>
			<IconButton
				aria-label={mode === "light" ? t("DarkMode") : t("LightMode")}
				size="sm"
				variant="outlined"
				color="neutral"
				onClick={() => {
					if (mode === "light") {
						setMode("dark");
					} else {
						setMode("light");
					}
				}}
				sx={{
					ml: "auto",
				}}
			>
				{mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
			</IconButton>
		</Tooltip>
	);
}
