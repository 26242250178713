import { extendTheme } from "@mui/joy/styles";
import "@fontsource/source-sans-pro";
import "@fontsource/ubuntu";

export default extendTheme({
	fontFamily: {
		display: "Ubuntu",
		body: "'Source Sans Pro', var(--joy-fontFamily-fallback)",
	},
});
