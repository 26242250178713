import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import SyncIcon from "@mui/icons-material/Sync";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { PropsWithChildren } from "react";

function MenuLink({
	content,
	icon,
	href,
	disabled = false,
}: PropsWithChildren<{
	icon: React.ReactNode;
	content: string;
	href: string;
	disabled?: boolean;
}>) {
	const { pathname } = useRouter();

	const isActive = pathname.startsWith(href);

	return (
		<ListItem>
			<ListItemButton
				component={Link}
				href={href}
				selected={isActive}
				disabled={disabled}
				aria-current={isActive ? "page" : undefined}
			>
				<ListItemDecorator>{icon}</ListItemDecorator>
				<ListItemContent>{content}</ListItemContent>
			</ListItemButton>
		</ListItem>
	);
}

export function Navigation(): JSX.Element {
	const { t } = useTranslation();

	return (
		<List
			size="sm"
			sx={{
				gap: 1,
				"--List-nestedInsetStart": "30px",
				"--ListItem-radius": (theme) => theme.vars.radius.sm,
			}}
		>
			<MenuLink
				href="/services"
				content={t("Services")}
				icon={<MiscellaneousServicesIcon />}
			/>
			<MenuLink
				href="/transfers"
				content={t("Transfers")}
				icon={<SyncIcon />}
			/>
		</List>
	);
}
